<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading main">Communication Skills & Personality Developement Sessions</h3>
                <!-- <h5 class="pt-4">Our history</h5>
              <p>Led at felis arcu. Integer lorem lorem, tincidunt eu congue et, mattis ut ante. Nami suscipit, lectus id efficitur ornare, leo libero convalis nulla, vitae dignissim .</p>
                <ul class="about-list">
                    <li><i class="fa fa-check-square-o"></i> University Faculties organise teaching and research into individual subjects.</li>
                    <li><i class="fa fa-check-square-o"></i> The University is rich in history - its famous buildings attract visitors.</li>
                    <li><i class="fa fa-check-square-o"></i> 50 years of people, and achievements that continue to transform.</li>
                    <li><i class="fa fa-check-square-o"></i> The University's core values are as follows:freedom of thought.</li>
                </ul> -->
            </div>
            <!-- <div class="col-lg-6 pt-5 pt-lg-0">
                <img style="max-width: 100%;" src="assets/img/about.jpg" alt="">
            </div> -->
        </div>
    </div>
</section>

<section class="blog-section ng-scope" style="padding-bottom: 10%;">
    <div class="container">
        <div id="gallery-grid">
            <div class="row grid">


                <div *ngFor="let x of [1,2,3,4,5]" class="col-md-4 col-lg-3 collgwidth">
                    <div class="media media-hoverable justify-content-center galleryheight2">
                        <div class="position-relative w-100 " style="height: 200px;object-fit: cover;">
                            <img class="w-100 " style="height: 200px;object-fit: cover;" src="../../../assets/2024updates/Website & Flex Photo/Entreprenureship Awareness Programs/img ({{x}}).jpeg" alt="gallery-img">
                        </div>
                    </div>
                </div>
                <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]" class="col-md-4 col-lg-3 collgwidth">
                    <div class="media media-hoverable justify-content-center galleryheight2">
                        <div class="position-relative w-100 " style="height: 200px;object-fit: cover;">
                            <img class="w-100 "style="height: 200px;object-fit: cover;" src="../../../assets/2024updates/Website & Flex Photo/Entreprenureship Awareness Programs/img ({{x}}).jpg" alt="gallery-img">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<style>
    img {
        max-width: 100% !important;
    }

    .collgwidth {
        max-width: 100% !important;
        margin-top: 2%;
        flex: 0 0 33% !important;
    }
</style>