import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AffiliationComponent } from './about/affiliation/affiliation.component';
import { KeyfeaturesComponent } from './about/keyfeatures/keyfeatures.component';
import { ManagementdeskComponent } from './about/managementdesk/managementdesk.component';
import { OverviewComponent } from './about/overview/overview.component';
import { PrincipalprofileComponent } from './about/principalprofile/principalprofile.component';
import { VisionComponent } from './about/vision/vision.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CivilComponent } from './courses/civil/civil.component';
import { ElectricalComponent } from './courses/electrical/electrical.component';
import { MechanicalComponent } from './courses/mechanical/mechanical.component';
import { AcademictourComponent } from './events/academictour/academictour.component';
import { FestivalComponent } from './events/festival/festival.component';
import { FresherpartyComponent } from './events/fresherparty/fresherparty.component';
import { GamesComponent } from './events/games/games.component';
import { IndustrialvisitComponent } from './events/industrialvisit/industrialvisit.component';
import { SummertrainingComponent } from './events/summertraining/summertraining.component';
import { PhotosComponent } from './gallery/photos/photos.component';
import { VideosComponent } from './gallery/videos/videos.component';
import { HomeComponent } from './home/home.component';
import { CommitteesComponent } from './committees/committees.component';
import { CafeteriaComponent } from './infrastructure/cafeteria/cafeteria.component';
import { ELibraryComponent } from './infrastructure/e-library/e-library.component';
import { GymnasiumComponent } from './infrastructure/gymnasium/gymnasium.component';
import { HostelComponent } from './infrastructure/hostel/hostel.component';
import { InternetlabComponent } from './infrastructure/internetlab/internetlab.component';
import { LaboratoriesComponent } from './infrastructure/laboratories/laboratories.component';
import { LectureTheaterComponent } from './infrastructure/lecture-theater/lecture-theater.component';
import { LibraryComponent } from './infrastructure/library/library.component';
import { PptlectureComponent } from './infrastructure/pptlecture/pptlecture.component';
import { SeminarComponent } from './infrastructure/seminar/seminar.component';
import { TransportfacilityComponent } from './infrastructure/transportfacility/transportfacility.component';
import { GalleryComponent } from './placements/gallery/gallery.component';
import { OurRecruitersComponent } from './placements/our-recruiters/our-recruiters.component';
import { PlacementcellComponent } from './placements/placementcell/placementcell.component';
import { FacultyListComponent } from './faculty-list/faculty-list.component';
import { AuthGuard, logedGuard } from 'src/provider/guard/auth.guard';
import { StudentlistComponent } from './admin/studentlist/studentlist.component';
import { ResultComponent } from './exam/result/result.component';
import { AcdmicclaenderComponent } from './events/acdmicclaender/acdmicclaender.component';
import { ActivityComponent } from './events/activity/activity.component';
import { OurmouComponent } from './placements/ourmou/ourmou.component';
import { EdcncdcComponent } from './placements/edcncdc/edcncdc.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {path:'home',component:HomeComponent},
  {path:'overview',component:OverviewComponent},
  {path:'vision',component:VisionComponent},
  {path:'managementdesk',component:ManagementdeskComponent},
  {path:'principalprofile',component:PrincipalprofileComponent},
  {path:'affiliation',component:AffiliationComponent},
  {path:'keyfeatures',component:KeyfeaturesComponent},
  {path:'electrical',component:ElectricalComponent},
  {path:'mechanical',component:MechanicalComponent},
  {path:'civil',component:CivilComponent},
  {path:'e-library',component:ELibraryComponent},
  {path:'library',component:LibraryComponent},
  {path:'pptlecture',component:PptlectureComponent},
  {path:'laboratories',component:LaboratoriesComponent},
  {path:'hostel',component:HostelComponent},
  {path:'seminar',component:SeminarComponent},
  {path:'lecture-theater',component:LectureTheaterComponent},
  {path:'internetlab',component:InternetlabComponent},
  {path:'cafeteria',component:CafeteriaComponent},
  {path:'gymnasium',component:GymnasiumComponent},
  {path:'transportfacility',component:TransportfacilityComponent},
  {path:'industrialvisit',component:IndustrialvisitComponent},
  {path:'summertraining',component:SummertrainingComponent},
  {path:'fresherparty',component:FresherpartyComponent},
  {path:'festival',component:FestivalComponent},
  {path:'academictour',component:AcademictourComponent},
  {path:'games',component:GamesComponent},
  {path:'photos',component:PhotosComponent},
  {path:'videos',component:VideosComponent},
  {path:'contactus',component:ContactusComponent},
  {path:'committees',component:CommitteesComponent},
  {path:'gallery',component:GalleryComponent},
  {path:'ourrecruiters',component:OurRecruitersComponent},
  {path:'placementcell',component:PlacementcellComponent},
  {path:'faculty-list',component:FacultyListComponent},
  {path:'academic-calendar',component:AcdmicclaenderComponent},
  {path:'activity',component:ActivityComponent},
  {path:'ourmou',component:OurmouComponent},
  {path:'edcncdc',component:EdcncdcComponent},
  { path: 'exam', loadChildren: () => import('./exam/examination/examination.module').then(m => m.ExaminationModule) },
  {path:'admin',component:StudentlistComponent ,canActivate: [logedGuard]},
  { path: 'result', component: ResultComponent ,canActivate: [logedGuard]},


  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
