<h6 class="heading main main-header">
    Diploma in Electrical Engineering
</h6>
<section>

    <h6 class="heading">
        About Department
    </h6>
    <p class="pera center">
        Electrical Engineering has a very valuable heritage of teaching and research. In Electrical Engineering, there
        is growing awareness that the future training and education of Electrical Engineers in the industry needs to be
        in the area of highly efficient,
        industry-relevant skills formation, to enable frequent and rapid deployment of professional skills in new areas.
        The primary objective of the department has been to impart quality education, training and research at the
        diploma level in various
        areas of Electrical Engineering with broad emphasis such as Integrated Electronics and Circuits,
        Tele-communications, Computer Technology, Control & Automation, Power Systems & Power Electronics. The
        department possesses industry collaborative
        laboratories which enhances the interest of students in research. The cutting edge technology in laboratories
        paves a strong way to the students of Electrical Engineering not only in academics but in research also. The
        students avail the facilities
        of the college in full-fledged manner which makes them to receive many laurels from national and international
        contests. The lab facilities and the infrastructure are regularly upgraded and developed and are well maintained
        to the needs of Corporate
        world. The activities of the department include fundamental research, sponsored and consultancy projects, and
        are carried out with active participation of the students, faculty and corporate engineers The faculty in the
        department is a rich blend
        of personnel with industrial and professional experience. The dedicated staff members have sound knowledge in
        emerging areas like embedded systems, power electronics applications in power systems, expert systems, etc. The
        faculty of the department
        continuously carries out research on latest technologies and regularly publishes in many top international
        journals. The breadth and depth of the research interests of the academic staff ensures a high standard of
        lecture courses and provides
        excellent opportunities for challenging and stimulating final year projects. Individual lecturers supplement
        their delivery using blackboards, overhead projectors and video projection. The Electrical Engineering
        Department holds several national
        level conferences, seminars and workshops. These provide a platform for the staff and students to share their
        views and experience among industrialists, fellow researchers and academicians in the emerging areas of
        electrical engineering. The academic
        strength of the faculty is reflected by the alumni, many of whom are in the top echelons of industry.


    </p>
    <hr>
    <div class="row justifysection">
        <!-- <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="item-work">
                <div class="hover">
                    <img class="mediaimages" style="height: 555px;width: 100%;" src="assets/img/vis-mis.jpg" alt="" />
                </div>
            </div>
        </div> -->


        <div class="col-md-12 col-lg-12 col-sm-12 para left">
            <br>


            <h3 class="heading">VISION OF THE DEPARTMENT</h3>
            <p class="center">To create competent and industry relevant electrical diploma engineers with professional
                and social values to meet global challenges.</p>

            <br>
            <hr>
            <h3 class="heading">MISSION OF THE DEPARTMENT</h3>
            <h6 class="heading left">
                Mission 1
            </h6>

            <p>To develop professional skills and technical manpower.</p>
            <br>
            <h6 class="heading left">
                Mission 2
            </h6>

            <p>To develop leadership qualities, communication skills, professional ethics, critical thinking and
                attitude of lifelong learning.</p>
            <br>
            <h6 class="heading left">
                Mission 3
            </h6>

            <p>To provide strong theoretical-practical engineering fundamentals and promoting in-house training,
                industrial visit, project activities etc.</p>
            <br>




            <hr>

            <h6 class="heading left">
                <a href="../../../assets/pdf/CO EE.pdf" target="_blank"> Course Outcome</a>
            </h6>
            <hr>
            <ul class="scmsylbus">
                <h6 class="heading left">
                    Syllabus & Scheme
                </h6>
                <li>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/1st Sem Scheme.pdf">1st Sem Scheme</a>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/1st Sem Syllabus.pdf">1st Sem Syllabus</a>
                </li>
                <li>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/2nd Sem Scheme.pdf">2nd Sem Scheme</a>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/2nd Sem Syllabus.pdf">2nd Sem Syllabus</a>
                </li>
                <li>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Sem Scheme.pdf">3rd Sem Scheme</a>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Sem Syllabus.pdf">3rd Sem Syllabus</a>
                </li>
                <li>

                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/4th Sem Syllabus.pdf">4th Sem Syllabus</a>
                </li>
                <li>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/2nd Year Scheme.pdf">2nd Year Scheme</a>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/2nd Year Syllabus.pdf">2nd Year Syllabus</a>
                </li>
                <li>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Year Scheme.pdf">3rd Year Scheme</a>
                    <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Year Syllabus.pdf">3rd Year Syllabus</a>
                </li>
            </ul>







            <!-- <div>
                <h3 class="heading">PROGRAM OUTCOMES (PO's)</h3>

                <p>1. Basic and Discipline specific knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.</p>
                <br>

                <p>2. Problem analysis: Identify and analyse well-defined engineering problems using effective engineering tools.</p>
                <br>

                <p>3. Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.</p>
                <p>4. Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.</p>
                <br>
                <p>5. Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.</p>

                <br>
                <p>6. Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.</p>

                <br>
                <p>7. Life-long learning: Ability to analyse individual needs and engage in updating in the context of technological changes.</p>

                <br>
                <p>8. Communication: Ability to communicate effectively and write effective reports and design documents.</p>

                <br>
            </div> -->
        </div>

    </div>
    <div class="row">

        <div class="col-md-12">
            <h2 id="event" class="heading main">EVENTS</h2>
        </div>
        <div class="col-md-12">
            <article class="post" style="margin-top: 5%;">
                <div class="clearfix"></div>
                <div class="row portfolioContainer" style="margin-top: 3%;">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 350px;width: 100%;" src="assets/img/course/ele/e3/1.jpg" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 350px;width: 100%;" src="assets/img/course/ele/e3/2.png" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            </article>


            <article class="post" style="margin-top: 5%;">
                <div class="clearfix"></div>
                <div class="row portfolioContainer" style="margin-top: 3%;">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 350px;width: 100%;" src="assets/img/course/ele/e4/1.jpg" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 350px;width: 100%;" src="assets/img/course/ele/e4/2.png" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            </article>





            <article class="post" style="margin-top: 5%;">
                <div class="clearfix"></div>
                <div class="row portfolioContainer" style="margin-top: 3%;">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 350px;width: 100%;" src="assets/img/course/ele/e6/1.jpg" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 350px;width: 100%;" src="assets/img/course/ele/e9/3.jpg" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            </article>






            <!-- <article class="post">
                <div class="clearfix"></div>
                <div class="row works portfolioContainer" style="margin-top: 3%;">


                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/e9/3.jpg" alt="" />
                                <a class="fancybox">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>



                </div>

            </article> -->
        </div>
    </div>
    <br>
    <div class="row">


        <div class="col-md-12">
            <h2 id="visit" class="main heading">INDUSTRIAL VISIT</h2>
            <h3 class="heading">PYROTECH PVT. LTD SECURE LTD UDAIPUR
            </h3>
            <div class="row works portfolioContainer">
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 220px;width: 45%;display: block;margin: 0 auto;"
                                src="assets/img/course/ele/industry/i1/3.jpg" alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

            <h3 class="heading">POWER SYSTEM, UDAIPUR</h3>
            <div class="row portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i2/1.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i2/2.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i2/3.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>
            <h3 class="heading">Solar Observatory Fatehsagar, Udaipur</h3>
            <div class="row portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i3/1.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i3/2.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i3/3.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

            <h3 class="heading">Harsh Electric & Engineering (Electrical Panel Manufacturer)</h3>
            <div class="row portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i4/1.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i4/2.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i4/3.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

            <h3 class="heading">Grid Connected Solar Panel</h3>
            <div class="row portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i5/1.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i5/2.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i5/3.png"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>

    <hr>
    <div class="row">

        <div class="col-md-12 botom-cont">
            <h2 style="text-align: center;" id="research" class="heading main">Center for Excellence</h2>
            <p> 1. PLC & SCADA Lab, innovad Automation, Udaipur </p>
            <p> 2. Power Controle Lab, Prateek Power, Udaipur </p>
        </div>
    </div>
    <hr>
    <div class="row">

        <div class="col-md-12 botom-cont">
            <h2 style="text-align: center;" id="research" class="heading main">RESEARCH AND PUBLICATION</h2>
            <p> [I] Luv Sharma, Shubhi Jain (2016) Consequence of PAPR Reduction in OFDM System on Spectrum and Energy
                Efficiencies Using Modified PTS Algorithm. In: Satapathy S., Bhatt Y., Joshi A., Mishra D. (eds)
                Proceedings of the International Congress
                on Information and Communication Technology. Advances in Intelligent Systems and Computing, vol 438.
                Springer, Singapore.
            </p>
            <p> [II] Luv Sharma, Dr. Ajay Kr. Vyas, “An Brief Analysis of Radio over Fibre (RoF) Technology & its
                Channel EstimationTechniques”, National Conference on Recent Advances in Wireless Communication &
                Artificial- Intelligence(RAWCAI-2014),published
                in proceedings of International Journal of Computer Applications (0975 – 8887), pp.52-56,March-2014.</p>
            <p>
            <p>[III] Sapna Dangi, Bonish,Heena Jain, Luv Sharma “An Inclusive study on Power System Stability
                Enhancement with Various Soft Computing Strategies” International Conference on Creativity and
                Innovation in Technology Development 2018,Volume
                5, Special Issue 05, March-2018 (UGC Approved) e-ISSN (O): 2348-4470, p-ISSN (P): 2348-6406</p>

            <p> [IV] Siraj Ansari, Sudhanshu Ranjan, Raunak Gachchha, Luv Sharma “An Investigation of Energy Proficient
                Routing Methods and CorrelatedEncounters in MANET International Conference on Creativity and Innovation
                in Technology Development 2018,Volume
                5, Special Issue 05, March-2018 (UGC Approved) e-ISSN (O): 2348-4470, p-ISSN (P): 2348-6406.</p>

            <p>[V] Luv Sharma Asst.Professor SS College of Engineering Udaipur , Mr Mahed Abroshan S,Asst.Professor
                Dept. Of Electrical Engineering Univesity of Cambridge, UK “EVOLUTION OF ROF SYSTEM IN WIRELESS
                COMMUNICATION” International Conference
                on Creativity and Innovation in Technology Development 2018,Volume 5, Special Issue 05, March-2018 (UGC
                Approved) e-ISSN (O): 2348-4470, p-ISSN (P): 2348-6406.
            </p>
            <p>[VI] Luv Sharma, Dr. Pranay Joshi, Mukesh Sharma, Deepak Tak “ A SURVEY OF ENERGY EFFICIENT ROUTING
                TECHNIQUES AND RELATED CHALLENGES IN MANET ”International Journal For Technological Research In
                Engineering ISSN (Online): 2347 - 4718
                National Conference on Importance of Inter-disciplinary Studies in Higher Education in the areas of
                Engineering, Science, Management and Humanities
            </p>
            <p>[VII] Luv Sharma, Dr. Pranay Joshi, Lawrence Jain, Deepak Tak “ MANET ROUTING PROTOCOLS BASED ON A SOFT
                COMPUTING TECHNIQUE (SWARM INTELLIGENCE)” ”International Journal For Technological Research In
                Engineering ISSN (Online): 2347 - 4718
                National Conference on Importance of Inter-disciplinary Studies in Higher Education in the areas of
                Engineering, Science, Management and Humanities
            </p>
            <p>[VIII] Karishma Kothari, Piyush Sharma, Luv Sharma “Cluster Head Election in Wireless Sensor networks : A
                Review”. Journals for International Shodh in Engineering & Technology ISSN:2454-9606, VOL:2, ISSUE 3
                July 2017.
            </p>
            <p>[IX] Karishma Kothari, Piyush Sharma, Luv Sharma “Genetically Optimised Clustering Probability & Fuzzy
                logic based clustering approach in Wireless Sensor networks” International Journal of Digital
                Application & contemporary research ,
                ISSN :2319-4863, VOL:6, ISSUE 10,May 2018.
            </p>
            <p>[X] V. K. Tayal, J. S. Lather, Piyush Sharma and S. K. Sinha, “Power System Stability Enhancement Using
                Fuzzy Logic based Power System Stabilizer” Proceedings of the Third International Conference on SocPros
                2013 , Advances in Intelligent
                Systems and Computing, 2014 ,vol 259,pp. 55-68, DOI: 10.1007/978-81-322-1768-8_2, @ Springer India
            </p>
            <p>[XI] Swati Paliwal, Piyush Sharma,Ajit Kumar Sharma, “Power System Stability Im.provement Using Different
                Controllers”, Fourth International Conference on SocPros 2014 , Advances in Intelligent Systems and
                Computing, 2014 ,Volume 1 , pp
                571-583, DOI: 10.1007/978-81-322-2217-0_46, @ Springer India.
            </p>
            <p>[XII] Smrati Singh, Ankit Kumar, Piyush Sharma, “Speed Control of Multilevel Inverter Based Induction
                Motor Using v/f Method” Fourth International Conference on SocPros 2014 , Advances in Intelligent
                Systems and Computing, 2014 ,Volume
                1 , pp 231-243, DOI: 10.1007/978-81-322-2217-0_20, Springer India.
            </p>
            <p>[XIII] Piyush Sharma,ChardraprabhaPandey, ShivpratapPandey&SachinSaini “Performance Analysis Of Power
                System Stability Using Fuzzy Logic Approach” IEEE International Conference on Emerging Trends in
                Electrical, Electronics & Sustainable
                Energy Systems (ICETEESES-16)
            </p>
            <p>[XIV] Piyush Sharma ,ArpitVarshney and Deepti Gupta, “A Review on Power Quality Improvement via Custom
                Power Devices” in 2nd International Conference on Emerging Trends in Engineering & Technology, April12,
                13, 2013
            </p>
            <p>[XV] Piyush Sharma and TriptiSaha, “Performance Analysis of Uncontrolled Ac/Dc Converter Using Different
                Types of Passive Filter” Proc. of 2nd International Conference on Emerging Trends in Engineering &
                Technology, April12, 13, 2013
            </p>
            <p>[XVI] Swati Paliwal, Vikas Singh Bhadoria and Piyush Sharma, “Renewable Energy Potential Assessment in
                Indian Perspective” International Journal of Engineering Research and Technology (IJERT). ISSN 0974-3154
                Volume 6, Number 6 (2013),
                pp. 801-810
            </p>
            <p>[XVII] ShivpratapPandey,KunalGaurav and Piyush Sharma, “An IDBUTM Filter for The Removal of High Density
                Impulse Noise”, National Conference on Recent Advances in Wireless Communication & Artificial-
                Intelligence(RAWCAI-2014),published
                in proceedings of International Journal of Computer Applications (0975 – 8887), pp.33-36,March-2014.
            </p>
            <p>[XVIII] KunalGaurav , ShivpratapPandey and Piyush Sharma, “A Survey To Manet Techniques Research and
                Related Challenges”, National Conference on Recent Advances in Wireless Communication & Artificial-
                Intelligence(RAWCAI-2014), published
                in proceedings of International Journal of Computer Applications (0975 – 8887),pp.11-14,March-2014.
            </p>
            <p>[XIX] Nikita Jain, ShivpratapPandey, Piyush Sharma, “Selection of Appropriate Detection Scheme for
                Optimum Performance-Complexity Trade-Off in 3GPP Suburban Macrocell Wireless MIMO Environments”
                International Journal of Computer Applications(IJCA)
                (0975 – 8887) Volume 131 – No.16 pp-23-26, December2015.
            </p>
            <p>[XX] Anjali Ajay Desai, Piyush Sharma, “Mixed Noise Removal Method Based on Sparse Representation and
                Dictionary learning: WESNR”INTERNATIONAL JOURNAL FOR RESEARCH & DEVELOPMENT IN TECHNOLOGY (IJRDT) ,
                Volume-7,Issue-3 ,ISSN (O) :- 2349-3585,
                pp-276-282, March-2017</p>
            <p>[XXI] Anshu Gaur, Piyush Sharma, ShivpratapPandey, “HDL and Timing Analysis of ABMA AHB on FPGA Platform”
                IEEE 2nd International Conference on Recent Developments in Control, Automation & Power Engineering
                (RDCAPE-2017). pp. 22-27, 26-27
                Oct. 2017
            </p>
            <p>[XXII] MeghaSoni, PiyushSharma“Speed Dictators For Forced Speed Limiting At Critical Places Using Low
                Cost RF Transceivers” International Journal of Advanced Research In Computer Science, Volume 8, No. 5,
                May – June 2017
            </p>
            <p>[XXIII] Anshu Gaur, Piyush Sharma, Shivpratappandey, “HDL Implementation of ABMA AHB on FPGA”
                INTERNATIONAL JOURNAL OF RESEARCH IN ENGINEERING & TECHNOLOGY (IJRDT) ,,ISSN (O) :- 2319-3585,
                pp-148-153, June-2017
            </p>
            <p>[XXIV] KoratKrupallben , Piyush Sharma, “Relay Selection Using Amplify & Forward And Decoded and Forward
                Relaying Techniques For Cooperative Communication” International Journal Of Digital Application And
                Contemporary Research , ISSN No:
                2319-4863, May 2017
            </p>
            <p>[XXV] Dheeraj Kumar Dhaked , Sachin Saini , Piyush Sharma, “Reactive Power Management Of Grid Associated
                Wind Farms In The State Of Gujarat” International Journal For Technological Research In Engineering,
                ISSN (Online): 2347 – 4718, Feb
                2018
            </p>
            <p>[XXVI] Dheeraj Kumar Dhaked , Sachin Saini , Piyush Sharma, “A Review Paper On Power System Stability
                Enhancement Using Various Control Strategies”, International Journal For Technological Research In
                Engineering, ISSN (Online): 2347 –
                4718, Feb 2018
            </p>
            <p>[XXVII] Piyush Sharma , Dheeraj Kumar Dhaked , Luv Sharma , Anupa Savoriya, “A Comprehensive Review On
                Power System Stability Enhancement With Different Soft Computing Techniques “, International Journal For
                Technological Research In Engineering,
                ISSN (Online): 2347 – 4718, Feb 2018
            </p>
            <p>[XXVIII] Piyush Sharma , Dheeraj Kumar Dhaked , Sachin Saini, “A Comprehensive Review On Power System
                Stability Enhancement With Different Control Technologies”, International Journal For Technological
                Research In Engineering, ISSN (Online):
                2347 – 4718, Feb 2018
            </p>
            <p>[XXIX] Sachin Saini , Dheeraj Kumar Dhaked , Piyush Sharma, “A Comprehensive Review Of Different Control
                Techniques For Thepermanent Magnet Brushless Dc Motor Drives”, International Journal For Technological
                Research In Engineering, ISSN
                (Online): 2347 – 4718, Feb 2018
            </p>
            <p>[XXX] Smrati Singh , Arpit Varshnry , Piyush Sharma ,“A Comprehensive Review Paper On Power Quality
                Enhancement Via Custom Power Devices”, International Journal of Advance Engineering and Research
                Development, ISSN (O): 2348-4470, March
                2018.
            </p>
            <p>[XXXI] Arpit Varshnry , Smrati Singh , Piyush Sharma, “Detailed investigation of Uncontrolled AC/DC
                Converter with Different Passive Filters”, International Journal of Advance Engineering and Research
                Development, ISSN (O): 2348-4470,
                March 2018.
            </p>
            <p>[XXXII] Ranjan Kumar Mandal, Kritin Joshi, Piyush Sharma, “Monitoring System for Wireless Power”,
                International Journal of Advance Engineering and Research Development, ISSN (O): 2348-4470, March 2018.
            </p>
            <p>[XXXIII] Naresh Kumar Meena , Prakhar Patel ,Praveen Kumar Dendor ,Piyush Sharma, “An Analysis on Power
                System Stability Improvement with Different Governing Approaches”, International Journal of Advance
                Engineering and Research Development,
                ISSN (O): 2348-4470, March 2018.
            </p>
            <p>[XXXIV] Piyush Sharma, Dheeraj k. Dhaked, Sachin Saini, Love Kumar Tripathi, Ashish Srivastav, “Analysis
                of Different Converters for Reduced Total Harmonic Distortion and Improved Power Factor”, 2018 5th IEEE
                International Conference on
                Signal Processing and Integrated Networks (SPIN), DOI: 10.1109/SPIN.2018.8474197
            </p>
            <p>[XXXV] Karishma Kothari, Piyush Sharma, Luv Sharma, “Cluster Head Election in Wireless Sensor Networks: A
                Review”, Journals for International Shodh in Engineering and Technology , (Volume 02, Issue 3, July
                2017)
            </p>
            <p>[XXXVI] Piyush Sharma, Dheeraj Kumar Dhaked and Ashok Kumar Sharma, “Mathematical Modeling and Simulation
                of DC-DC Converters using State Space Approach” , Springer, 2020 (Under Process)
            </p>

            <br />

            <h3 class="heading">National Conference :</h3>
            <p>[I] Piyush Sharma and TriptiSaha ,“Analysis of Different Converters for Reduced Total Harmonic Distortion
                and Improved Power Factor” Proc. of IEEE Sponsored 1st National Conference on Power Electronics Systems
                & Applications, PESA ’13.pp
                103-109,2013
            </p>
            <p>[II] Piyush Sharma and Swati Paliwal, “Feeder Analysis With GPS Interfacing Using MIPOWER Software” Proc.
                of National Conference on Emerging Trends For Sustainable Development In Engineering And
                Technology,NCETSDET’2014, pp 57-60, 2014,
                ISBN 978-81-7906-317-0</p>
            <p>[III] Rakesh Kumar, Ajay Kumar, RaunitVerma, Piyush Sharma and Kusum Sharma, “Overview Of Energy Storage
                Technologies For Electric Power” Proc. of National Conference on Emerging Trends For Sustainable
                Development In Engineering And Technology,NCETSDET’2014,
                pp 119-121, 2014, ISBN 978-81-7906-317-0</p>
            <p>[IV] Chandra PrabhaPandey and Piyush Sharma , “ Performance Analysis of Power System Stability Using Soft
                Computing Technique ” Proc. of National Conference on Emerging Trends For Sustainable Development In
                Engineering And Technology,NCETSDET’2014,
                pp 08-13, 2014, ISBN 978-81-7906-317-0
            </p>

        </div>
    </div>
    <hr>
    <div>
        <h3 class="heading">PROGRAM SPECIFIC OUTCOMES (PSOs)</h3>
        <p class="heading left">After the successful completion of Diploma Programme in Electrical Engineering, the
            students will able to:</p>
        <ul style="font-size: 17px;
        line-height: 2;
        margin-top: 16px;list-style:auto">
            <li>Apply the fundamental knowledge of mathematics, science, electrical engineering to analyse and solve the
                complex problems in electrical, electronics and allied interdisciplinary areas.
            </li>
            <li>Design, develop and implement electrical and allied interdisciplinary projects to meet the demands of
                industry and to provide solutions to the current real time problems.
            </li>
        </ul>



        <!-- <h3 class="heading left">3. Methodical Reasoning</h3>
        <p>Inculcate diagnostic reasoning and decisive thinking through effective teaching learning process and hands on hands training to develop R&D with innovative spirit and entrepreneurship skills. </p>

        <br>
        <h3 class="heading left">4. Lifelong Learning</h3>
        <p>Motivating students to develop an ability to pursue higher studies research relevant to their discipline for their exponential career growth and to create enthusiasm for the enduring the learning process.</p> -->

        <br>
    </div>
    <!-- <a href="">Institution Of Engineers,India(IEI)</a> -->
    <hr>
    <div class="row">


        <div class="col-md-12">
            <h2 id="visit" class="main heading">Professional Body</h2>



            <div class="row portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="../../../assets/img/certificates/EE.jpg" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="../../../assets/img/certificates/ME.jpg" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="../../../assets/img/certificates/third.jpg"
                                alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>





        </div>

    </div>




</section>