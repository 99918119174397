import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
// import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  private scrollInterval: any;

  images = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);
  homepopup: Boolean = false;
  showmenu: Boolean = false;
  showA: Boolean = false;
  showC: Boolean = false;
  showI: Boolean = false;
  showP: Boolean = false;
  showE: Boolean = false;
  showG: Boolean = false;
  showN: Boolean = false;
  getheight: any;
  @ViewChild('myCarousel') myCarousel: any;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
    load: 3,
    slide: 1,
    interval: { timing: 4000, initialDelay: 1000 },
    loop: true,
    touch: true,
    velocity: 0.2,
    vertical: {
      enabled: true,
      height: 600
    }
  }
  carouselItems: any[any] = [1, 2, 3, 4];
  mainItems: any[] = [...this.carouselItems]















  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    var t = this;
    console.log(window.innerWidth);
    if (window.innerWidth > 480) {
      t.getheight = true;
      t.showA = true;
      t.showC = true;
      t.showI = true;
      t.showP = true;
      t.showE = true;
      t.showG = true;
      t.showN = true;
    } else {
      t.getheight = false;

    }
    window.addEventListener('resize', function (event) {
      debugger;
      console.log(window.innerWidth);
      if (window.innerWidth > 480) {
        t.getheight = true;
        t.showA = true;
        t.showC = true;
        t.showI = true;
        t.showP = true;
        t.showE = true;
        t.showG = true;
        t.showN = true;
      } else {
        t.getheight = false;
      }
    }, true);
  }
  ngOnInit(): void {

    if (window.innerWidth > 480) {

      this.showA = true;
      this.showC = true;
      this.showI = true;
      this.showP = true;
      this.showE = true;
      this.showG = true;
      this.showN = true;
    }


    setTimeout(() => {
      this.startAutoScroll();
    }, 3000);

  }
  toggle() {
    this.showmenu = !this.showmenu;
  }
  subtoggle(flag: any) {
    debugger;
    this[flag] = !this[flag];
  }


  ngOnDestroy(): void {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }


  startAutoScroll(): void {
    debugger;
    const container = this.scrollContainer.nativeElement;

    this.scrollInterval = setInterval(() => {
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        container.scrollTop = 0; // Reset to top
      } else {
        container.scrollTop += 1; // Increment scroll
      }
    }, 20); // Adjust interval for smoothness or speed
  }
}


