<header>
    <div class="wrapper">
        <div class="head-img">
            <img src="assets/img/home/logo.png">
        </div>
        <div class="heading no-board">
            <h1 class="title top">SS Polytechnic College, Udaipur, Rajasthan</h1>
            <div class="d-flex gap-10 flex-wrap-sm">
                <img class="nbalogo" src="../../assets/logo/nba.png">

                <!-- <p class="subtitle">Approved by AICTE, New Delhi <br> Affiliated to Board of Technical Education Rajasthan, Jodhpur
            </p> -->
                <p class="subtitle">
                    <span class="nbatitle">NBA Accredited</span>
                    <br>
                    (Mechanical Engineering & Electrical Engineering)
                </p>
                <h4>
                    <div class="contact-info">
                        <b class="d-block mb-2"> Contact information</b>
                        <span class="lh-baseline mb-1"> Rajasthan :
                            9829179662</span>
                        <span class="d-block lh-lg">
                            Bihar & Jharkhand :
                            9950558666,9523350581</span>
                    </div>
                    <a class="glow_button admission-btn ml-auto mb-2 mt-0 d-block w-fit position-relative-sm"
                        (click)="openDialog()" target="_blank">Admission
                        Enquiry</a>
                </h4>
                <!-- <h4>Call us : +91 9950558666</h4> -->
                <!-- <h4> <a class="button ml-auto mb-2" href="../../assets/admission.jpeg" target="_blank">Admission
                        Open</a></h4> -->
                <!-- <h4> </h4> -->
            </div>
        </div>
    </div>
    <nav [class.scrolled]="isscrolled">
        <div class="navbar">
            <ion-icon class="fs-4 d-lg-none" name='menu' (click)="toggle()"></ion-icon>
            <div class="nav-links" [class]="{'show':showmenu,'hide':!showmenu}">
                <div class="sidebar-logo">
                    <span class="logo-name"><img style="width: 40px;" src="../../assets/img/home/logo.png"
                            alt=""></span>
                    <ion-icon name="close" class="fs-4" (click)="toggle()"></ion-icon>
                </div>
                <ul class="links">
                    <!-- <mat-icon class='bx bx-menu' style="color: red;">close</mat-icon> -->
                    <li routerLink="/home"><a>Home</a></li>
                    <li>
                        <a (click)="subtoggle('showA')">About Us</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="htmlCss-sub-menu sub-menu" *ngIf="showA">
                            <li><a routerLink="/overview">Overview</a></li>
                            <li><a routerLink="/vision">Vision and Mission</a></li>
                            <li><a routerLink="/principalprofile">Principal's Profile</a></li>
                            <li><a routerLink="/managementdesk">Management Desk</a></li>
                            <li><a routerLink="/affiliation">Affiliation and Approval</a>
                            </li>
                            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLScl6i3tz1oOiqhs_11FDM_R_DYF1QxvIUVLM1xwV_rzk3L-4A/viewform?usp=sf_link"
                                    target="_blank">Online Grievance Redressal</a></li>
                            <!-- <li><a href="../../assets/pdf/19-4-22/Accreditation Poly.pdf"
                                    target="_blank">Accreditation</a></li> -->
                            <li><a href="../../assets/2024updates/SSPC mandatory disclosure.pdf"
                                    target="_blank">Mandatory Disclosure</a></li>

                            <!-- <li><a routerLink="/keyfeatures">Key Features</a></li> -->
                            <!-- <li><a routerLink="/faculty-list">Faculty Detail</a></li> -->
                        </ul>
                    </li>
                    <li>
                        <a (click)="subtoggle('showC')">Courses</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu" *ngIf="showC">
                            <li><a routerLink="/electrical">Diploma in Electrical Engineering</a></li>
                            <li><a routerLink="/mechanical">Diploma in Mechanical Engineering</a></li>
                            <li><a routerLink="/civil">Diploma in Civil Engineering</a></li>
                        </ul>
                    </li>
                    <li>
                        <a (click)="subtoggle('showI')">Infrastructure</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu" *ngIf="showI">
                            <li class="more">
                                <span><a>Central Library</a>
                                    <ion-icon name="chevron-forward"></ion-icon>

                                </span>
                                <ul class="more-sub-menu sub-menu">
                                    <li routerLink="/library"><a>Library</a></li>
                                    <li><a routerLink="/e-library">E-Library</a></li>
                                    <li><a href="https://swayam.gov.in/" target="_blank">Mooc Courses on
                                            Swayam</a></li>
                                </ul>
                            </li>
                            <li><a routerLink="/laboratories">Laboratories and Workshops</a></li>
                            <li><a routerLink="/hostel">Hostel Facility</a></li>
                            <li><a routerLink="/seminar">Seminar & Conference Hall</a></li>
                            <li><a routerLink="/lecture-theater">Lecture Theaters</a></li>
                            <li><a routerLink="/internetlab">Internet Lab</a></li>
                            <li><a routerLink="/cafeteria">Cafeteria</a></li>
                            <li><a routerLink="/gymnasium">Gymnasium</a></li>
                            <li><a routerLink="/transportfacility">Bus Facility</a></li>
                        </ul>
                    </li>
                    <li>
                        <a (click)="subtoggle('showP')">Placements</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu" *ngIf="showP">

                            <!-- <li class="more">
                                <span><a >Placement at a Glance</a>
                                     <ion-icon name="chevron-forward"></ion-icon>
                                    
                                </span>
                                <ul class="more-sub-menu sub-menu">
                                    <li><a href="../../assets/pdf/placement/2018-19.pdf" target="_blank">Placement 2018-19</a></li>
                                    <li><a href="../../assets/pdf/placement/2019-20.pdf" target="_blank">Placement 2019-20</a></li>
                                    <li><a href="../../assets/pdf/placement/2020-21.pdf" target="_blank">Placement 2020-21</a></li>

                                </ul>
                            </li> -->
                            <li><a routerLink="/ourmou">Our MoU</a></li>
                            <li><a (click)="goToRecriters()">Our Recruiters</a></li>
                            <li><a (click)="goTogallery()">Placement Gallery</a></li>
                            <li><a routerLink="/edcncdc">EDC & CDC</a></li>
                            <li><a (click)="goToPlacementcell()">Contact Placement Cell</a></li>
                        </ul>
                    </li>
                    <li>
                        <a (click)="subtoggle('showE')">Events</a>
                        <ion-icon name="chevron-down"></ion-icon>

                        <ul class="js-sub-menu sub-menu" *ngIf="showE">
                            <li><a routerLink="/academic-calendar">Academic Calendar</a></li>
                            <li><a routerLink="/activity">Departmental Activity</a></li>
                            <li><a routerLink="/industrialvisit">Industrial Visits</a></li>
                            <li><a routerLink="/summertraining">Summer Training</a></li>
                            <li><a routerLink="/fresherparty">Fresher Party & Annual Function</a></li>
                            <li><a routerLink="/festival">Festival Celebration</a></li>
                            <li><a routerLink="/academictour">Academic Tour</a></li>
                            <li><a routerLink="/games">Games and Sports</a></li>
                        </ul>
                    </li>
                    <li>
                        <a (click)="subtoggle('showG')">Gallery</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu" *ngIf="showG">
                            <li><a routerLink="/photos">Photos</a></li>
                            <li><a routerLink="/videos">Videos</a></li>
                        </ul>
                    </li>
                    <li *ngIf="api.nStdid > 0">
                        <a (click)="exam()">Exam</a>
                    </li>
                    <li *ngIf="api.nStdid == 0">
                        <a (click)="register()">Register</a>
                    </li>
                    <li *ngIf="api.nStdid == 0">
                        <a (click)="login()">Login</a>
                    </li>
                    <!-- <li *ngIf="api.nStdid > 0">
                        <a (click)="logout()">Logout</a>
                    </li> -->
                    <!-- <li>
                        <a (click)="subtoggle('showG')">AQAR</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu" *ngIf="showG">
                            <li><a href="../../assets/pdf/AQAR/EOA_Report_2020-2.pdf" target="_blank">AQAR 2020-21</a></li>
                            <li><a href="../../assets/pdf/AQAR/EOA_Report 21-22.pdf" target="_blank">AQAR 2021-22</a></li>
                            <li><a href="../../assets/pdf/AQAR/EOA Report 22-23 SSPC.pdf" target="_blank">AQAR 2022-23</a></li>
                        </ul>
                    </li> -->
                    <!-- <li>
                        <a (click)="subtoggle('showG')">Miscellaneous</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu" *ngIf="showG">
                            <li><a href="../../assets/pdf/approvel/Academic Calender-2021-22.pdf" target="_blank">Academic Calender</a>
                            </li>   <li><a routerLink="/e-library">E-Library</a></li>
                            <li><a routerLink="/pptlecture">PPT Slides</a></li>
                            <li><a href="../../assets/pdf/">BTER</a></li>
                            <li><a href="https://www.aicte-india.org">AICTE</a></li>
                            <li><a href="../../assets/pdf/Bter Norms.PDF" target="_blank">BTER Norms</a></li>
                            <li><a href="../../assets/pdf/Code of conduct for student.PDF" target="_blank">Code of Conduct</a></li>
                            <li><a href="../../assets/pdf/HR manual.PDF" target="_blank">HR Manual</a></li>
                          
                            <li class="more">
                                <span><a >Audit Report</a>
                                     <ion-icon name="chevron-forward"></ion-icon>

                                </span>
                                <ul class="more-sub-menu sub-menu">
                                    <li><a href="../../assets/pdf/12-1-22/Audit (FY-2016-17).pdf" target="_blank">Audited(FY 2016-17)</a></li>
                                    <li><a href="../../assets/pdf/12-1-22/Audit (FY-2017-18).pdf" target="_blank">Audited(FY 2017-18)</a></li>
                                    <li><a href="../../assets/pdf/12-1-22/Audit (FY-2018-19).pdf" target="_blank">Audited(FY 2018-19)</a></li>
                                    <li><a href="../../assets/pdf/12-1-22/Audit (FY-2019-20).pdf" target="_blank">Audited(FY 2019-20)</a></li>
                                    <li><a href="../../assets/pdf/12-1-22/Audit (FY-2016-17).pdfarget=" target="_blank">Budget 20-21</a></li>
                                    <li><a href="../../assets/pdf/BUDGET & AUDIT/Budget 21-22.PDF" target="_blank">Budget 21-22</a></li>
                                </ul>
                            </li>
                            <li class="more">
                                <span><a >Student Feedback</a>
                                     <ion-icon name="chevron-forward"></ion-icon>
                                    
                                </span>
                                <ul class="more-sub-menu sub-menu">
                                    <li><a href="../../assets/pdf/ACTION TAKEN & FEED BACK/Student Feedback Form.PDF" target="_blank">Student Feedback Form</a></li>
                                    <li><a href="../../assets/pdf/ACTION TAKEN & FEED BACK/Action Taken.PDF" target="_blank">Action Taken Report</a></li>

                                </ul>
                            </li>
                            <li><a href="../../assets/pdf/Govering Body.pdf" target="_blank">Governing Body</a></li>
                            <li><a href="../../assets/pdf/alumni sspc.pdf" target="_blank">Alumni Registration</a></li>
                            <li><a href="../../assets/pdf/12-1-22/Dissemination of Information.pdf" target="_blank">Dissemination of Information</a></li>

                        </ul>
                    </li> -->
                    <!-- <li>
                        <a (click)="subtoggle( 'showG')">NBA</a>
                        <ion-icon name="chevron-down"></ion-icon>
                        <ul class="js-sub-menu sub-menu lastmenu" *ngIf="showG">

                            <li class="more">
                                <span> <ion-icon name="chevron-back"></ion-icon><a >Institute Level</a>
                                    
                                </span>
                                <ul class="more-sub-menu sub-menu lastdrop">
                                    <li>
                                        <a href="https://youtu.be/GUlmdCqYuX8"> Video Tour</a>
                                    </li>
                                    <li>
                                        <span>  <ion-icon name="chevron-back"></ion-icon><a >Course File </a>
                                      
                                  </span>
                                        <ul class="more-sub-menu sub2-menu">
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-3 (COURSE FILES-FIRST YEAR)/(a) CHEMISTRY COURSE FILE.PDF" target="_blank">Applied Chemistry</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-3 (COURSE FILES-FIRST YEAR)/maths-I.pdf" target="_blank">Maths-1</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-3 (COURSE FILES-FIRST YEAR)/(d) Mechanics Course File.PDF" target="_blank">Mechanics</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-3 (COURSE FILES-FIRST YEAR)/(c) Computer Course File.PDF" target="_blank">IT System</a></li>
                                        </ul>
                                    </li>

                                    <li>
                                        <span>  <ion-icon name="chevron-back"></ion-icon><a >Lab Records </a>
                                      
                                  </span>
                                        <ul class="more-sub-menu sub2-menu">
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-4(LABORATORY RECORD-FIRST YEAR)/(a)CHEMISTRY LAB MANUAL.PDF" target="_blank">Chemistry</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-4(LABORATORY RECORD-FIRST YEAR)/(b)COMMUNICATION LAB MANUAL.PDF" target="_blank">Comm. Skill</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-4(LABORATORY RECORD-FIRST YEAR)/(c) Mechanics Lab Manual.PDF" target="_blank">Mechanics</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-4(LABORATORY RECORD-FIRST YEAR)/(d)EEE LAB MANUAL.PDF" target="_blank">BEE</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>  <ion-icon name="chevron-back"></ion-icon><a >Time Table </a>
                                      
                                  </span>
                                        <ul class="more-sub-menu sub2-menu">

                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-5(TIME TABLE OF FIRST YEAR)/TIME TABLE 2018-19.PDF" target="_blank">2018-2019</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-5(TIME TABLE OF FIRST YEAR)/TIME TABLE 2019-20.PDF" target="_blank">2019-2020</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-5(TIME TABLE OF FIRST YEAR)/TIME TABLE 2020-21(I SEMESTER).PDF" target="_blank">2020-2021(1st Sem)</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-5(TIME TABLE OF FIRST YEAR)/TIME TABLE 2020-21(II SEMESTER).PDF" target="_blank">2020-2021(2nd Sem)</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-5(TIME TABLE OF FIRST YEAR)/TIME TABLE 2021-22(I SEMESTER).PDF" target="_blank">2021-2022(1st Sem)</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="../../assets/pdf/7-1-22/Institute file/I-6(FACULTY LIST OF FIRST YEAR)/I-6(FACULTY LIST OF FIRST YEAR (CAY,CAYm1,CAYm2).PDF" target="_blank">Faculty List</a>

                                    </li>
                                    <li><a href="../../assets/pdf/7-1-22/Institute file/I-8(FEEDBACK)/merged.pdf" target="_blank">Feedback</a>

                                    </li>
                                    <li>
                                        <span>  <ion-icon name="chevron-back"></ion-icon><a >GB/GC </a>
                                 </span>
                                        <ul class="more-sub-menu sub2-menu">
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(a)GOVERNING BODY.PDF" target="_blank">GB</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(b)DEPARTMENT ADVISORY BOARD.PDF" target="_blank">DAB</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(c)ANTI-RAGGING COMMITTEE.PDF" target="_blank">ARC</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(d)LIBRARY COMMITTEE.PDF" target="_blank">LC</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(e)FACULTY GRIEVANCE.PDF" target="_blank">FG</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(f)STUDENT GRIEVANCE.PDF" target="_blank">SG</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(g)WOMEN DEVELOPMENT CELL.PDF" target="_blank">WDC</a></li>
                                            <li><a href="../../assets/pdf/7-1-22/Institute file/I-7(COMPOSITION OF GOVERNING BODY)/(h)PURCHASE COMMITTEE.PDF" target="_blank">PC</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li class="more">
                                <span> <ion-icon name="chevron-back"></ion-icon><a >Programme Level</a>
                                    
                                </span>
                                <ul class="more-sub-menu sub-menu lastdrop">
                                    <li>
                                        <span>  <ion-icon name="chevron-back"></ion-icon><a >Electrical Engineering</a>
                                      
                                  </span>
                                        <ul class="more-sub-menu sub2-menu">
                                            <li class="more">
                                                <li class="more">
                                                    <li><a href="https://youtu.be/dXZuVG5DSoo" target="_blank">Video Tour</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/2. EE SAR.pdf" target="_blank">SAR</a></li>
                                                    <li><a href="" target="_blank">PPT</a></li>
                                                    <li>
                                                        <a href="../../assets/pdf/BUDGET & AUDIT/Budget 20-21.PDF" target="_blank">
                                                            <span>  <ion-icon name="chevron-back"></ion-icon> Course File</span>
                                                            <ul class="more-sub-menu sub3-menu">
                                                                <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/Course File/4. COURSE FILE 1.pdf" target="_blank">Course file 1</a></li>
                                                                <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/Course File/4. COURSE FILE 2.pdf" target="_blank">Course file 2</a></li>
                                                                <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/Course File/4. COURSE FILE 3.pdf" target="_blank">Course file 3</a></li>
                                                                <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/Course File/4. COURSE FILE 4.pdf" target="_blank">Course file 4</a></li>

                                                            </ul>
                                                        </a>
                                                    </li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/5. LABORATORY RECORD.pdf" target="_blank">Lab Record</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/6. PROJECT REPORT WITH EVALUATION RECORD.pdf" target="_blank">Project Report</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/7. TIME TABLE FOR ALL SEMESTER YEAR.pdf" target="_blank">Time Table</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/8. DEPARTMENTAL FACULTY LIST.pdf" target="_blank">Faculty List</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/9. STUDENTS LIST OF CAY FOR ALL THREE YEARS.pdf" target="_blank">Student List</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/10. PLACEMENT LIST 2020,2019,2018.pdf" target="_blank">Placement List</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/11. DETAILED CURRICULUM.PDF" target="_blank">Syllabus</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/12. STUDENTS CLEARED THE PROGRAM.pdf" target="_blank">Passout Student</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/13. PUBLICATION.pdf" target="_blank">Publication</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/14. CONSULTANCY.PDF" target="_blank">Consultancy</a></li>
                                                    <li><a href="../../assets/pdf/7-1-22/program Level/EE Program Level for Online Visit/15. ACADEMIC AUDIT REPORT.pdf" target="_blank">Acedmic Audit Report</a></li>
                                        </ul>
                                        </li>
                                        <li>
                                            <span>  <ion-icon name="chevron-back"></ion-icon><a >Mechanical Engineering</a>
                                      
                                  </span>
                                            <ul class="more-sub-menu sub2-menu">
                                                <li><a href="https://youtu.be/B_I0Mmf2YRY" target="_blank">Video Tour</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/SAR ME.pdf" target="_blank">SAR</a></li>
                                                <li><a href="" target="_blank">PPT</a></li>
                                                <li>
                                                    <a href="../../assets/pdf/BUDGET & AUDIT/Budget 20-21.PDF" target="_blank">
                                                        <span>  <ion-icon name="chevron-back"></ion-icon> Course File</span>
                                                        <ul class="more-sub-menu sub3-menu">
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/4. Course File/Course 1.pdf" target="_blank">Course file 1</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/4. Course File/Course 2.pdf" target="_blank">Course file 2</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/4. Course File/Course 3.pdf" target="_blank">Course file 3</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/4. Course File/Course 4.pdf" target="_blank">Course file 4</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/4. Course File/Course 5.pdf" target="_blank">Course file 5</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/4. Course File/Course 6.pdf" target="_blank">Course file 6</a></li>
                                                        </ul>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="../../assets/pdf/BUDGET & AUDIT/Budget 20-21.PDF" target="_blank">
                                                        <span>  <ion-icon name="chevron-back"></ion-icon> Lab Record</span>
                                                        <ul class="more-sub-menu sub3-menu">
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/5. Lab Record/Laboratory Manual/Lab Manual 1- Manufacturing lab.pdf" target="_blank">Lab Manual 1- Manufacturing lab</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/5. Lab Record/Laboratory Manual/Lab Manual 2- RAC Lab.pdf" target="_blank">Lab Manual 2- RAC Lab</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/5. Lab Record/Laboratory Record/Lab Record 1 (TE&HT).pdf" target="_blank">Laboratory Record/Lab Record 1 (TE&HT)</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/5. Lab Record/Laboratory Record/Lab Record 2 (FM & HM).pdf" target="_blank">Laboratory Record/Lab Record 2 (FM & HM)</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/5. Lab Record/Assesment, Rubrics & Implementation.pdf" target="_blank">Assesment, Rubrics & Implementation</a></li>
                                                        </ul>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="../../assets/pdf/BUDGET & AUDIT/Budget 20-21.PDF" target="_blank">
                                                        <span>  <ion-icon name="chevron-back"></ion-icon> Project Report</span>
                                                        <ul class="more-sub-menu sub3-menu">
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Evaluation Records.pdf" target="_blank">Evaluation Records</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Project Report 1.pdf" target="_blank">Project Report 1</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Project Report 2.pdf" target="_blank">Project Report 2</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Project Report 3.pdf" target="_blank">Project Report 3</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Project Report 4.pdf" target="_blank">Project Report 4</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Project Report 5.pdf" target="_blank">Project Report 5</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/6. Project Report/Project Report 6.pdf" target="_blank">Project Report 6</a></li>
                                                        </ul>
                                                    </a>
                                                </li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/Time table .pdf" target="_blank">Time Table</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/8. Faculty List.pdf" target="_blank">Faculty List</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/9. Student List.pdf" target="_blank">Student List</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/10. Placement List.pdf" target="_blank">Placement List</a></li>
                                                <li>
                                                    <a href="../../assets/pdf/BUDGET & AUDIT/Budget 20-21.PDF" target="_blank">
                                                        <span>  <ion-icon name="chevron-back"></ion-icon>Syllabus</span>
                                                        <ul class="more-sub-menu sub3-menu">
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/11. Syllabus/SEMESTER SCHEME.pdf" target="_blank">Semester Scheme</a></li>
                                                            <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/11. Syllabus/YEARLY SCHEME.pdf" target="_blank">Yearly Scheme</a></li>
                                                        </ul>
                                                    </a>
                                                </li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/12. Passout Students.pdf" target="_blank">Passout Student</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/13. Publications.pdf" target="_blank">Publication</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/14. Consultancy.pdf" target="_blank">Consultancy</a></li>
                                                <li><a href="../../assets/pdf/7-1-22/program Level/ME Program Level/ME- Program Level/Upload/15. Academic Audit Report.pdf" target="_blank">Acedmic Audit Report</a></li>
                                            </ul>
                                        </li>

                                </ul>
                                </li>
                                <li class="more">
                                    <span> <ion-icon name="chevron-back"></ion-icon><a >Placement at a Glance</a>
                                    
                                </span>
                                    <ul class="more-sub-menu sub-menu lastdrop">
                                        <li><a href="../../assets/pdf/placement/2018-19.pdf" target="_blank">Placement 2018-19</a></li>
                                        <li><a href="../../assets/pdf/placement/2019-20.pdf" target="_blank">Placement 2019-20</a></li>
                                        <li><a href="../../assets/pdf/placement/2020-21.pdf" target="_blank">Placement 2020-21</a></li>

                                    </ul>
                                </li>
                               
                        </ul>
                        </li> -->

                    <li class="contact-li">
                        <div class="d-flex align-items-center gap-2">
                            <button routerLink="/contactus"  class="button brand w-sm-100" >Contact Us</button>
                            <!-- <button class="Contact" routerLink="/contactus">Contact Us</button> -->
                            <button *ngIf="api.nStdid > 0" (click)="logout()"  class="button brand w-sm-100" >
                                Logout
                                <ion-icon class="fs-6 m-0" name="log-out-outline"></ion-icon>
                            </button>
                            <button *ngIf="api.nStdid > 0 && api.isAdmin =='Y'" (click)="goToStudent()"  class="button brand w-sm-100" >
                                <!-- Students -->
                                <ion-icon class="fs-6 m-0" name="people"></ion-icon>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>