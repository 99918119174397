<div class="container">


    <h3 class="detailheading heading" style="padding-bottom: 30px;"> Campus Placement Drive  </h3>
    <div class="row works portfolioContainer">
        <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]" class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/2024updates/Website & Flex Photo/Placement Drives/img ({{x}}).jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
            </div>
        </div>

    </div>
    <hr>
</div>


<div class="container">


    <h3 class="detailheading heading" style="padding-bottom: 30px;"> Hostel  </h3>
    <div class="row works portfolioContainer">


        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/Hostel/1.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/Hostel/5.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/Hostel/DSCN0388.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/Hostel/DSCN0402.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/Hostel/DSCN0404.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;"
                        src="../../../assets/img/Gallery1/Hostel/vlcsnap-2014-03-21-12h30m34s36.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;"
                        src="../../../assets/img/Gallery1/Hostel/vlcsnap-2014-03-21-12h57m34s53.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>



    </div>


</div>


<div class="container">


    <h3 class="detailheading heading" style="padding-bottom: 30px;"> Library  </h3>
    <div class="row works portfolioContainer">


        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0116 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0117 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0119 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0118 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0122 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0123 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0124 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0130 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/DSC_0134 copy.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/Lib rary.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>
        
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/library 1.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/library 2.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/library.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/library12.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/library2.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 256px;width: 100%;" src="../../../assets/img/Gallery1/library/libray21.JPG">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>


            </div>
        </div>

        
        
        

    </div>


</div>