<div class="container-main topimage">
    <div class="modal-dialog modal-dialog-scrollable show popupmodal" *ngIf="homepopup">
        <button class="btn btn-primary ms-auto" (click)="homepopup=false">Close</button>
        <img src="../assets/img/website poly.jpg">
    </div>
    <div class="section-1">
        <!-- <div class="container-mq1">
            <div class="item-mq1">
                <h2>04 <br> Collages</h2>
            </div>
            <div class="item-mq1">
                <h2>04 <br> Hostel</h2>
            </div>
            <div class="item-mq1">
                <h2>4100 <br> ALUMNI</h2>
            </div>
            <div class="item-mq1">
                <h2>2000+ <br> WORKING <br> PROFESSIONALS</h2>
            </div>
        </div> -->
    </div>
    <div class="section-3">

        <div class="container-mq1">
            <div class="item-mq1">
                <img src="../../assets/img/home/faculty.svg">
                <h2> Faculty</h2>
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/placement.svg">
                <h2> Placement</h2>
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/library.svg">
                <h2> E-Library</h2>
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/aboutus.svg">
                <h2> About Us</h2>
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/newsleter.svg">
                <h2>Newsletter</h2>
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/register.svg">
                <h2>Registrations</h2>
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/gallery.svg">
                <h2>Gallery</h2>
            </div>
        </div>
    </div>
    <div class="section-4">
        <div class="row">
            <div class="col-lg-4">
                <div class="heading">
                    <div class="title">Upcoming Events</div>
                    <!-- <div class="subtitle">Building a better world, one course at a time</div> -->
                    <div class="scrolling-box" style="height: 300px;overflow: auto;" #scrollContainer>
                        <h6>5- Days Management Development Program By MSME 11 Dec 2024</h6>
                        <h6>One day Industrial Visit at Prateek Power industry in 15 Dec 2024</h6>
                        <h6>Fresher’s Party on 28 December 2024</h6>
                        <h6>WINTER Sports day Celebration on 25 DECEMBER 2024</h6>
                        <h6>One day Industrial Tour " SCOPE of Diploma Engineers in Mining Industries" at Rajsamand Dec 2024</h6>
                        <h6>2-Day Academic / Recreational Tour at Ahmedabad Science city Dec 2024</h6>
                        <h6>1 Day Visit at K.L. Engineers Udaipur in Dec 2024</h6>
                        <h6>2 Days FDP on "Role of Engineers in development of Engineering resources" in Jan 2025</h6>
                        <h6>2 Days International Conference ICCITD-25 dated Jan 2025</h6>
                        <h6>PLACEMENT DRIVE by GR Infra PVT LTD Jan 2025</h6>
                        <h6>PLACEMENT DRIVE by ROSE Marbles in Jan 2025</h6>
                        <h6>PRE PLACEMENTS talk by Excellence - An Attitude Skill Development Centre in Feb 2025</h6>
                        <h6>Industrial Visit at Mehsana RIICO Industrial AREA in Feb 2025</h6>
                        <h6>Inter College Academic cum Sports Competition from 11 Feb 2025</h6>
                        <h6>5 Days Skill Development programme conducted by BABA Automobiles PVT ltd from Feb 15, 2025</h6>
                        <h6>2 Days Personality development Programme Conducted by SKILL INDIA in March 2025</h6>
                        <h6>Mini Project Exhibition/Paper Presentation in March 2025</h6>
                        <h6>Farewell Party “ADIOS 2025” on 29 MARCH 2025</h6>
                        <h6>GREEN MARATHON-RUN for ENVIRONMENT in MARCH 2025</h6>
                        <h6>2 Days technical workshop on ARDUINO by ARD TECHNOLOGIES in March 2025</h6>
                        <h6>Inter-college Indoor Sports competition in MARCH 2025</h6>
                        
                    </div>

                </div>
            </div>
            <div class="col-lg-8">
                <div class="heading">
                    <div class="title">OUR Achievement</div>
                    <!-- <div class="subtitle">Building a better world, one course at a time</div> -->
                </div>
                <div class="container-mq1">
                    <div class="item-mq1">
                        <div class="top">
                            <img src="../../assets/img/home/achieve.svg">
                            <h2> Highest Number of Admisson</h2>
                        </div>
                        <div class="bottom">
                            <h4> College of Southern Rajasthan With Highest Admisson</h4>
                        </div>
                    </div>
                    <div class="item-mq1">
                        <div class="top"> <img src="../../assets/img/home/achieve.svg">
                            <h2> Clean and Green Campus</h2>
                        </div>
                        <div class="bottom">
                            <h4>Clean & Green Campus Awarded by UCCI, Udaipur</h4>
                        </div>
                    </div>
                    <div class="item-mq1">
                        <div class="top"> <img src="../../assets/img/home/achieve.svg">
                            <h2> Best Quality & Caring Institute</h2>
                        </div>
                        <div class="bottom">
                            <h4>Our college is best Quality & Caring Technical Institute</h4>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="section-5">
        <div class="heading">
            <div class="title">OUR Courses</div>
            <div class="subtitle">Building a better world, one course at a time</div>
        </div>
        <div class="container-mq1">
            <div class="item-mq1 first">
                <div class="top">
                    <h2>Electrical Engineering</h2>
                </div>
                <div class="bottom">
                    <h4>The organization has well equipped labs viz, Electrical m/c, Power Electronics & Drives, Power
                        System & High Voltage, Measurement.. ......</h4>
                </div>
                <div class="end">
                    <h4>View Detail</h4>
                </div>



            </div>
            <div class="item-mq1 scnd">
                <div class="top">
                    <h2>Mechanical Engineering</h2>
                </div>
                <div class="bottom">
                    <h4>Mechanical engineers require a solid understanding of key concepts including mechanics,
                        kinematics, thermodynamics,...</h4>
                </div>
                <div class="end">
                    <h4>View Detail</h4>
                </div>



            </div>
            <div class="item-mq1 three">
                <div class="top">
                    <h2>Civil Engineering</h2>
                </div>
                <div class="bottom">
                    <h4>Civil engineering is a professional engineering discipline that deals with the design,
                        construction, and maintenanc...</h4>
                </div>
                <div class="end">
                    <h4>View Detail</h4>
                </div>



            </div>





        </div>
    </div>

    <div class="section-7">
        <div class="heading">
            <div class="title">Gallery</div>
        </div>
        <div class="container-mq1">
            <carousel autoplay="true" id="crous_1">
                <div class="carousel-cell">
                    <div class="item-mq1">
                        <img src="assets/img/gallery/facility/facility20.jpg" alt="">


                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="item-mq1">
                        <img src="assets/img/gallery/facility/facility21.jpg" alt="">


                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="item-mq1">
                        <img src="assets/img/gallery/facility/facility22.jpg" alt="">


                    </div>
                </div>


                <div class="carousel-cell">
                    <div class="item-mq1">
                        <img src="assets/img/gallery/facility/facility1.jpg" alt="">


                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="item-mq1">
                        <img src="assets/img/gallery/facility/facility2.jpg" alt="">


                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="item-mq1">
                        <img src="assets/img/gallery/facility/facility3.jpg" alt="">


                    </div>
                </div>


            </carousel>
        </div>
        <div class="heading">
            <div class="btn">View All</div>
        </div>
    </div>
    <div class="section-8">
        <div class="heading">
            <div class="subtitle">We have earned the trust of 4100 students , including these fine companies</div>
        </div>
        <div class="container-mq1">

            <div class="item-mq1">
                <img src="../../assets/img/logos/aqualogo.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/arcgate.svg" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/arvlilogo.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/epl-logo.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/Golchalogo.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/GSMOTORS.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/ratnamani_logo.png" alt="">

            </div>

            <div class="item-mq1">
                <img src="../../assets/img/logos/JSUTDAIL.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/logobrownnew.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/Pyrotech-logo-1.png" alt="">

            </div>

            <div class="item-mq1">
                <img src="../../assets/img/logos/pacific.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/prateekpower.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/marbles.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/hemae.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/sachinmotors.jpg" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/skh-logo.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/sswl.jpg" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/vision.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/logos/voltas.png" alt="">

            </div>
            <div class="item-mq1">
                <img src="../../assets/img/placement/Aquatronics.jpg" alt="">

            </div>





        </div>
    </div>
    <div class="section-9">
        <div class="heading">
            <div class="title">OUR Benifits</div>

        </div>
        <div class="container-mq1">
            <div class="item-mq1">
                <div class="top">
                    <img src="../../assets/img/home/hostle.png">
                    <h2>HOSTEL FACILITY</h2>
                </div>
                <div class="bottom">
                    <h4> Hostel is another home for students.The college provides rooms with attached bathroom to the
                        students with separate areas of residence for males and females.The hostels are well furnished
                        and aesthetically designed with a view to provide
                        best possible facilities to the students.</h4>
                </div>



            </div>
            <div class="item-mq1">
                <div class="top"> <img src="../../assets/img/home/librarye.png">
                    <h2>CENTRAL LIBRARY</h2>
                </div>
                <div class="bottom">
                    <h4>Library is the Hub of the Knowledge at the Institution to collectively support the
                        teaching,research and extension programmer,SSPC has a well stocked computerized library with the
                        number of 37,000 Books with National and international
                        journals.
                    </h4>
                </div>



            </div>
            <div class="item-mq1">
                <div class="top"> <img src="../../assets/img/home/award.png">
                    <h2>OUR AWARD</h2>
                </div>
                <div class="bottom">
                    <h4>SSPC is a symbol of excellence in technical education scenario in India. Because of the
                        deliverance of quality technical education and best practices SSPC has been awarded by Education
                        Leadership Award</h4>
                </div>



            </div>
            <div class="item-mq1">
                <div class="top"> <img src="../../assets/img/home/visit.png">
                    <h2>CREATIVE THINKING</h2>
                </div>
                <div class="bottom">
                    <h4>We have been imparting quality training to all our students by promoting creative thinking.We
                        believe in creating students who become a source of inspiration for the society.Being creative
                        helps the students to be innovative and encourages
                        them to learn new things.</h4>
                </div>



            </div>





        </div>
    </div>
    <!-- <div class="section-10">
        <div class="heading">
            <div class="title">Blog Post</div>
        </div>
        <div class="container-mq1">


            <carousel autoplay="true" cellsToShow="4">
                <div class="carousel-cell">

                    <div class="item-mq1">
                        <h6 class="date">12 Dec 2021</h6>
                        <div class="top">
                            <h2>Examination</h2>
                        </div>
                        <div class="bottom">
                            <h4>20 days National Workshop on Industrial Automation and Its Application</h4>
                        </div>



                    </div>









                </div>
                <div class="carousel-cell">

                    <div class="item-mq1">
                        <h6 class="date">12 Dec 2021</h6>
                        <div class="top">
                            <h2>Examination</h2>
                        </div>
                        <div class="bottom">
                            <h4>20 days National Workshop on Industrial Automation and Its Application</h4>
                        </div>



                    </div>









                </div>
                <div class="carousel-cell">

                    <div class="item-mq1">
                        <h6 class="date">12 Dec 2021</h6>
                        <div class="top">
                            <h2> Highest Number of Admisson</h2>
                        </div>
                        <div class="bottom">
                            <h4> College of Southern Rajasthan With Highest Admisson</h4>
                        </div>



                    </div>









                </div>
                <div class="carousel-cell">

                    <div class="item-mq1">
                        <h6 class="date">12 Dec 2021</h6>
                        <div class="top">
                            <h2> Highest Number of Admisson</h2>
                        </div>
                        <div class="bottom">
                            <h4> College of Southern Rajasthan With Highest Admisson</h4>
                        </div>



                    </div>









                </div>
                <div class="carousel-cell">

                    <div class="item-mq1">
                        <h6 class="date">12 Dec 2021</h6>
                        <div class="top">
                            <h2> Highest Number of Admisson</h2>
                        </div>
                        <div class="bottom">
                            <h4> College of Southern Rajasthan With Highest Admisson</h4>
                        </div>



                    </div>









                </div>

            </carousel>
              </div>
    </div> -->

    <div class="section-12">

        <div class="container-mq1">


            <div class="left-div">
                <h4>Our group Of Institute</h4>
                <ul>

                    <li>SS POLYTECHNIC COLLEGE
                    </li>
                    <li>SS AYURVED MEDICAL COLLEGE & HOSPITAL
                    </li>

                    <li>SHUBH SHANTI VIHAR UNDER SHUBH REALTY
                    </li>
                </ul>
                <div class="logos">
                    <img src="../../assets/img/home/sslogo.png">
                    <img src="../../assets/img/home/edu.png">
                    <img src="../../assets/img/home/aicte.png">
                </div>
            </div>
            <!-- <div class="right">
                <img src="../../assets/img/home/building.png">
            </div> -->



        </div>
    </div>
    <!-- <div class="section-8 scnd">
        <div class="heading">
            <div class="title">Prominent Recruiters</div>
            <div class="subtitle">We have earned the trust of 4100 students , including these fine companies</div>
        </div>
        <div class="container-mq1">

            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand1.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand2.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand3.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand4.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand5.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand6.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand7.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand8.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand9.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand10.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand11.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand12.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand13.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand14.png" alt="">
            </div>
            <div class="item-mq1">
                <img src="../../assets/img/home/brands/brand15.png" alt="">
            </div>


        </div>
    </div> -->

</div>