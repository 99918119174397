<section class="inner-section1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="over-dta">
                    <div class="title-bar">
                        <h2 class="heading main">Academic Calendar</h2>
                    </div>

                    <div class="ebook-links">
                        <ul>
                            <li><a class="elibraryfont" href="../../../assets/2024updates/Academic Calendar/2022-23 odd.pdf" target="_blank"><b class="boldwordsclr">2022-23 Odd Semester</b></a></li>
                            <li><a class="elibraryfont" href="../../../assets/2024updates/Academic Calendar/2022-23 even semester.pdf" target="_blank"><b class="boldwordsclr">2022-23 Even Semester</b></a></li>
                            <li><a class="elibraryfont" href="../../../assets/2024updates/Academic Calendar/2023-24 odd semester.pdf" target="_blank"><b class="boldwordsclr">2023-24 Odd Semester</b></a></li>
                            <li><a class="elibraryfont" href="../../../assets/2024updates/Academic Calendar/2023-24 even.pdf" target="_blank"><b class="boldwordsclr">2023-24 Even Semester</b></a></li>
                            <li><a class="elibraryfont" href="../../../assets/2024updates/Academic Calendar/2024-25 odd semester.pdf" target="_blank"><b class="boldwordsclr">2024-25 Odd Semester</b></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>