<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-6 about-text">
                <h3>OUR MoU for Industry-Academic Interaction</h3>
                <p> 1. MSME Udaipur Extension Centre</p>
                <p> 2. ROSE Marbles Pvt. Ltd. </p>
                <p> 3. Espirit Stones Pvt. Ltd.</p>
                <p> 4. K.L. Engineers Udaipur</p>
                <p> 5. BHUMI NGO Chennai</p>
                <p> 6. Prateek Power Industries</p>
                <p> 7. Baba Automobiles Pvt. Ltd.</p>
                <p> 8. Excellence-An Attitude Skill Development Centre</p>
                <p> 9. VOLTFEB Industry Ltd.</p>
                <p> 10. Technocreation India Pvt. Ltd. </p>
                <p> 11. SUNSAFE Solar Infra Services</p>
            </div>

            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 about-text mb-4" *ngFor="let x of [1,2,3,4]">
                        <img class="w-100" style="object-fit: cover; height: 250px;" src=".././../../assets/2024updates/Website & Flex Photo/MOU Signed with MSME Udaipur Extension Center/img ({{x}}).jpeg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>