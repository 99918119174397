<div class="container"> <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">

        <mat-tab label="Mechanical">
            <div class="container mt-5">
                <div class="">
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2024-25 </h3>
                        <div class="">
                            <table class="table table-bordered text-center border">
                                <thead class="table-light">
                                    <tr>
                                        <th>S. N.</th>
                                        <th>Gap</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Gap-1</td>
                                        <td>Expert Lectures on Mathematics/ Science</td>
                                        <td>12-10-20</td>
                                        <td>Mr. Lokendra Kumawat <br> Asst. Prof. NIBM, Nathdwara</td>
                                        <td>Expert Lecture (Online)</td>
                                        <td>40</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2023-24 </h3>
                        <div class="">
                            <table class="table table-bordered text-center">
                                <thead class="table-light">
                                    <tr>
                                        <th>S. N.</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Expert talk on Car Suspension</td>
                                        <td>28-08-23</td>
                                        <td>Mr. O.P. Kharol, Owner, OM Motors Pvt. Ltd., Udaipur</td>
                                        <td>Offline</td>
                                        <td>54</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Online Expert Talk on Industrial Automation</td>
                                        <td>23-09-23</td>
                                        <td>Sofcon India Pvt Ltd, Ahmedabad</td>
                                        <td>Online</td>
                                        <td>52</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Expert talk on Autonomous Vehicles</td>
                                        <td>16-04-24</td>
                                        <td>Mr. P.K. Jain, Secretary, IIIE Udaipur Chapter</td>
                                        <td>Offline</td>
                                        <td>57</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Expert Lecture on Report Writing</td>
                                        <td>06-05-24</td>
                                        <td>Dr. Sahil Nawab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>53</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Pre Placement Talk</td>
                                        <td>10-05-24</td>
                                        <td>Wind World India Pvt Ltd</td>
                                        <td>Offline</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Pre Placement Talk</td>
                                        <td>18-05-24</td>
                                        <td>BANCO Products (INDIA) Ltd, Vadodara</td>
                                        <td>Offline</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Expert Lecture on Personality Development</td>
                                        <td>06-11-23</td>
                                        <td>T&P Cell SSPC, Udaipur</td>
                                        <td>Offline</td>
                                        <td>52</td>

                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Expert Lecture on Communication Skills</td>
                                        <td>02-12-23</td>
                                        <td>Dr. Sahil Nawab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>60</td>

                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Expert Lecture on Interview Techniques</td>
                                        <td>03-02-24</td>
                                        <td>T&P Cell SSPC, Udaipur</td>
                                        <td>Offline</td>
                                        <td>50</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2022-23 </h3>
                        <div class="">
                            <table class="table table-bordered text-center">
                                <thead class="table-light">
                                    <tr>
                                        <th>S. N.</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Hands on Practice on IC Engine</td>
                                        <td>05/09/2022 to 10/09/2022</td>
                                        <td>Trainer BABA Automobile Pvt. Ltd.</td>
                                        <td>Offline</td>
                                        <td>54</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Expert talk on Time management & Critical thinking</td>
                                        <td>29-09-22</td>
                                        <td>Mr. Anurag Tripathi, Chairman, Career Guru, Udaipur</td>
                                        <td>Offline</td>
                                        <td>55</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Expert Lecture on Report Writing</td>
                                        <td>05-05-23</td>
                                        <td>Dr. Sahil Nawab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>53</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Expert talk on Time management & Critical thinking</td>
                                        <td>29-09-22</td>
                                        <td>Mr. Anurag Tripathi, Chairman, Career Guru, Udaipur</td>
                                        <td>Offline</td>
                                        <td>55</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Expert Lecture on Personality Development</td>
                                        <td>05-11-22</td>
                                        <td>T&P Cell SSPC, Udaipur</td>
                                        <td>Offline</td>
                                        <td>54</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Expert Lecture on Communication Skills</td>
                                        <td>02-12-22</td>
                                        <td>Mr. Deepak Sharma, Director CDS English Academy, Udaipur</td>
                                        <td>Offline</td>
                                        <td>60</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Expert Lecture on Interview Techniques</td>
                                        <td>03-02-23</td>
                                        <td>T&P Cell SSPC, Udaipur</td>
                                        <td>Offline</td>
                                        <td>50</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2021-22 </h3>
                        <div class="">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Hands on Practice on Electric vehicle</td>
                                        <td>13/9/2021 to 18/09/2021</td>
                                        <td>Trainer BABA, Automobile Pvt. Ltd.</td>
                                        <td>Offline</td>
                                        <td>45</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Industrial Visit</td>
                                        <td>22-09-21</td>
                                        <td>G.R. Infra Projects Pvt. Ltd., Udaipur</td>
                                        <td>Offline</td>
                                        <td>34</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Expert Lecture on Mathematics</td>
                                        <td>12-10-21</td>
                                        <td>Mr. Lokendra Kumawat, Asst. Prof. NIMB, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>39</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Industrial Visit</td>
                                        <td>13-10-21</td>
                                        <td>Paiwal Ice Plant, Udaipur</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Expert Lecture on Mathematics</td>
                                        <td>19-10-21</td>
                                        <td>Mr. Lokendra Kumawat, Asst. Prof. NIMB, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>40</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Expert Lecture on Mathematics</td>
                                        <td>26-10-21</td>
                                        <td>Mr. Lokendra Kumawat, Asst. Prof. NIMB, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Expert talk on wind Energy</td>
                                        <td>24-11-21</td>
                                        <td>Mr. C.P. Jain, Chairman, EI Udaipur Local Chapter</td>
                                        <td>Offline</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Expert Lecture on Communication Skills</td>
                                        <td>02-12-21</td>
                                        <td>Dr. Sahil Navab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>59</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Expert Lecture on Personality Development</td>
                                        <td>11-12-21</td>
                                        <td>T&P Cell SSCP, Udaipur</td>
                                        <td>Offline</td>
                                        <td>53</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Expert Lecture on Interview Techniques</td>
                                        <td>03-02-22</td>
                                        <td>T&P Cell SSCP, Udaipur</td>
                                        <td>Offline</td>
                                        <td>54</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Expert Lecture on Higher Education</td>
                                        <td>12-02-22</td>
                                        <td>Dr. Prashant, Principal SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>49</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Expert talk on Vacuum Braking System</td>
                                        <td>18-02-22</td>
                                        <td>Mr. Saurav Sharma, Sr. Engineer SK Engineers, Udaipur</td>
                                        <td>Offline</td>
                                        <td>35</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td>Industrial Visit</td>
                                        <td>18-02-22</td>
                                        <td>Indo German Tool Room, Ahmadabad, Gujarat</td>
                                        <td>Offline</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>Industrial Visit</td>
                                        <td>26-03-22</td>
                                        <td>OM Motors Pvt. Ltd., Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>39</td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td>Expert talk on Additive Manufacturing</td>
                                        <td>13-04-22</td>
                                        <td>Mr. P.K. Jain, Secretary, IIIE Udaipur Chapter</td>
                                        <td>Offline</td>
                                        <td>32</td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>Expert Lecture on Report Writing</td>
                                        <td>05-05-22</td>
                                        <td>Dr. Sahil Navab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>51</td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td>Hands on Practice on Electric vehicle</td>
                                        <td>13/9/2021 to 18/09/2021</td>
                                        <td>Trainer BABA, Automobile Pvt. Ltd.</td>
                                        <td>Offline</td>
                                        <td>45</td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>Industrial Visit</td>
                                        <td>22-09-21</td>
                                        <td>G.R. Infra Projects Pvt. Ltd., Udaipur</td>
                                        <td>Offline</td>
                                        <td>34</td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td>Expert Lecture on Mathematics</td>
                                        <td>12-10-21</td>
                                        <td>Mr. Lokendra Kumawat, Asst. Prof. NIMB, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>39</td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td>Industrial Visit</td>
                                        <td>13-10-21</td>
                                        <td>Paiwal Ice Plant, Udaipur</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>21</td>
                                        <td>Expert Lecture on Mathematics</td>
                                        <td>19-10-21</td>
                                        <td>Mr. Lokendra Kumawat, Asst. Prof. NIMB, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>38</td>
                                    </tr>
                                    <tr>
                                        <td>22</td>
                                        <td>Expert Lecture on Mathematics</td>
                                        <td>26-10-21</td>
                                        <td>Mr. Lokendra Kumawat, Asst. Prof. NIMB, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>23</td>
                                        <td>Expert talk on wind Energy</td>
                                        <td>24-11-21</td>
                                        <td>Mr. C.P. Jain, Chairman, EI Udaipur Local Chapter</td>
                                        <td>Offline</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>24</td>
                                        <td>Expert Lecture on Communication Skills</td>
                                        <td>02-12-21</td>
                                        <td>Dr. Sahil Navab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>59</td>
                                    </tr>
                                    <tr>
                                        <td>25</td>
                                        <td>Expert Lecture on Personality Development</td>
                                        <td>11-12-21</td>
                                        <td>T&P Cell SSCP, Udaipur</td>
                                        <td>Offline</td>
                                        <td>53</td>
                                    </tr>
                                    <tr>
                                        <td>26</td>
                                        <td>Expert Lecture on Interview Techniques</td>
                                        <td>03-02-22</td>
                                        <td>T&P Cell SSCP, Udaipur</td>
                                        <td>Offline</td>
                                        <td>54</td>
                                    </tr>
                                    <tr>
                                        <td>27</td>
                                        <td>Expert Lecture on Higher Education</td>
                                        <td>12-02-22</td>
                                        <td>Dr. Prashant, Principal SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>49</td>
                                    </tr>
                                    <tr>
                                        <td>28</td>
                                        <td>Expert talk on Vacuum Braking System</td>
                                        <td>18-02-22</td>
                                        <td>Mr. Saurav Sharma, Sr. Engineer SK Engineers, Udaipur</td>
                                        <td>Offline</td>
                                        <td>35</td>
                                    </tr>
                                    <tr>
                                        <td>29</td>
                                        <td>Industrial Visit</td>
                                        <td>18-02-22</td>
                                        <td>Indo German Tool Room, Ahmadabad, Gujarat</td>
                                        <td>Offline</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>30</td>
                                        <td>Industrial Visit</td>
                                        <td>26-03-22</td>
                                        <td>OM Motors Pvt. Ltd., Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>39</td>
                                    </tr>
                                    <tr>
                                        <td>31</td>
                                        <td>Expert talk on Additive Manufacturing</td>
                                        <td>13-04-22</td>
                                        <td>Mr. P.K. Jain, Secretary, IIIE Udaipur Chapter</td>
                                        <td>Offline</td>
                                        <td>32</td>
                                    </tr>
                                    <tr>
                                        <td>32</td>
                                        <td>Expert Lecture on Report Writing</td>
                                        <td>05-05-22</td>
                                        <td>Dr. Sahil Navab Khan, Asst. Prof. SSCE, Udaipur</td>
                                        <td>Offline</td>
                                        <td>51</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Electrical">
            <div class="container mt-5">
                <div class="">
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2023-24 </h3>
                        <div class="">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Industrial Visit</td>
                                        <td>05-10-23</td>
                                        <td>Harsh Engineering, Kaladwas, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>63</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Industrial Visit</td>
                                        <td>05-10-23</td>
                                        <td>33/11 Grid Sub Station, Balicha, Transport Nagar, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>64</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Industrial Visit</td>
                                        <td>07-10-23</td>
                                        <td>GR Infra, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>63</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Industrial Visit</td>
                                        <td>20-10-23</td>
                                        <td>AVP Mines, Udaipur Cement Ltd, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Industrial Visit</td>
                                        <td>27-05-24</td>
                                        <td>MSME, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>65</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Expert Talk on Skill Development</td>
                                        <td>10-01-24</td>
                                        <td>Mr. Pawan Khatri, Red Hat</td>
                                        <td>Offline</td>
                                        <td>93</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Expert Talk on Electric Vehicle</td>
                                        <td>11-12-23</td>
                                        <td>Dr. Dheeraj Kumar Dhaked, IIT Guwahati</td>
                                        <td>Online</td>
                                        <td>93</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Expert Talk on Skill Build</td>
                                        <td>23-10-23</td>
                                        <td>Mr. Ashish Tripathi, Skill Build, IBM</td>
                                        <td>Offline</td>
                                        <td>80</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Project Exhibition</td>
                                        <td>28-11-23</td>
                                        <td>Mr. Dheeraj Soni, Udaipur</td>
                                        <td>Offline</td>
                                        <td>85</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Expert Talk on Mathematics (Session 1)</td>
                                        <td>11-09-23</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Expert Talk on Mathematics (Session 2)</td>
                                        <td>18-09-23</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Expert Talk on Mathematics (Session 3)</td>
                                        <td>25-09-23</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td>Pre Placement Talk</td>
                                        <td>18-05-24</td>
                                        <td>Mr. Kashyap Kachhiya, BENCO Products (INDIA) Ltd, Vadodara</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>Pre Placement Talk</td>
                                        <td>10-05-24</td>
                                        <td>Mr. Yogesh Patel, Wind World India Pvt Ltd, Gujarat</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td>Expert Talk on Industrial Automation</td>
                                        <td>23-09-23</td>
                                        <td>Mr. Vishal Vadher, Assistant Manager, Sofcon, New Delhi</td>
                                        <td>Offline</td>
                                        <td>76</td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>Expert talk on Entrepreneur Awareness Program Association with MSME, PPDC Agra</td>
                                        <td>22-03-24</td>
                                        <td>Mr. Ganesh, Director, MSME, Udaipur</td>
                                        <td>Offline</td>
                                        <td>105</td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td>Workshop on Word/ Excel & Power Point Presentation</td>
                                        <td>29-09-2023 to 30-09-2023</td>
                                        <td>Mr. Dheeraj Soni, Udaipur</td>
                                        <td>Offline</td>
                                        <td>75</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2022-23 </h3>
                        <div class="">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Expert talk on Interview technique and Pre-Placement Talk</td>
                                        <td>12-11-22</td>
                                        <td>Mr. Jaideep, HR, Marelli Motherson Automotive Lighting India Pvt Ltd, Gujrat</td>
                                        <td>Offline</td>
                                        <td>27</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Expert talk on Interview technique and Pre-Placement Talk</td>
                                        <td>16-11-22</td>
                                        <td>Ms. Neeti Bhatt, HR, Fusion Business Solutions Pvt Ltd, Udaipur</td>
                                        <td>Offline</td>
                                        <td>51</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Expert talk on Virtual labs</td>
                                        <td>17-12-22</td>
                                        <td>Er. Prateek Sharma, Virtual Labs, Senior Field Engineer, IIT Delhi</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Hands on Practice on Human Machine Interface (HMI)</td>
                                        <td>10-01-2023 to 12-01-2023</td>
                                        <td>Mr. Arpit Siyal, Director, Inovadd Automation, Udaipur</td>
                                        <td>Offline</td>
                                        <td>83</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Hands on Practice on Industrial Automation</td>
                                        <td>10-02-2023 to 12-02-2023</td>
                                        <td>Mr. Arpit Siyal, Director, Inovadd Automation, Udaipur</td>
                                        <td>Offline</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Expert Lecture on Career Opportunities after Diploma Engineering</td>
                                        <td>28-03-23</td>
                                        <td>Mr. Jitendra Tiwari, Made Easy, Jaipur</td>
                                        <td>Offline</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Industrial Visit</td>
                                        <td>26-09-22</td>
                                        <td>Solar Observatory, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>71</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Industrial Visit</td>
                                        <td>28-09-22</td>
                                        <td>Secure Meter, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Industrial Visit</td>
                                        <td>30-09-22</td>
                                        <td>Pyrotec, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>74</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Workshop on Word/ Excel & Power Point Presentation</td>
                                        <td>01-11-2022 to 02-11-2022</td>
                                        <td>Mr. Dheeraj Soni, Udaipur</td>
                                        <td>Offline</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Expert Talk on Mathematics (Session 1)</td>
                                        <td>01-10-22</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Expert Talk on Mathematics (Session 2)</td>
                                        <td>04-10-22</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td>Expert Talk on Mathematics (Session 3)</td>
                                        <td>03-10-22</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>82</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>Expert Talk on Skill Development</td>
                                        <td>29-03-23</td>
                                        <td>Mr. Sachin Saini, Sharda University, Greater Noida</td>
                                        <td>Offline</td>
                                        <td>68</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <h3 class="mb-0">SESSION 2021-22 </h3>
                        <div class="">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Action taken</th>
                                        <th>Date</th>
                                        <th>Resource Person with designation</th>
                                        <th>Mode</th>
                                        <th>No. of students present</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Expert Lecture on Renewable Energy for Power Generation</td>
                                        <td>30-11-21</td>
                                        <td>Mr. Mayank Singh, Electrical Engineering Department, RTU, Kota</td>
                                        <td>Online</td>
                                        <td>41</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Expert Lecture on Transmission and Distribution</td>
                                        <td>29-11-21</td>
                                        <td>Mr. Dheeraj Kumar Dhaked, Electrical Engineering Department, RTU, Kota</td>
                                        <td>Online</td>
                                        <td>76</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Industrial Visit</td>
                                        <td>01-12-21</td>
                                        <td>Grid Sub Station, Madri, Transport Nagar, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>56</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Industrial Visit</td>
                                        <td>01-12-21</td>
                                        <td>Unnathi CNC Technologies Pvt Ltd, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>45</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Industrial Visit</td>
                                        <td>03-12-21</td>
                                        <td>Rose Marble, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Industrial Visit</td>
                                        <td>04-12-21</td>
                                        <td>Prateek Industries, Udaipur</td>
                                        <td>Site Visit</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Expert Lecture on World Intellectual Property Day</td>
                                        <td>26-04-22</td>
                                        <td>Mr. Dheeraj Kumar Dhaked, Electrical Engineering Department, RTU, Kota</td>
                                        <td>Online</td>
                                        <td>40</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Expert Lecture on National Technology Day</td>
                                        <td>11-05-22</td>
                                        <td>Mr. Bharat Dubey, Sterling and Wilson, New Delhi</td>
                                        <td>Online</td>
                                        <td>35</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Workshop on House Wiring</td>
                                        <td>23-05-2022 to 24-05-2022</td>
                                        <td>Mr. Dinesh Nagda</td>
                                        <td>Offline</td>
                                        <td>76</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Entrepreneurship</td>
                                        <td>28-06-22</td>
                                        <td>IIT Madras</td>
                                        <td>Online</td>
                                        <td>71</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Entrepreneurship "Out of the box thinking for problem solving"</td>
                                        <td>28-06-22</td>
                                        <td>Mr. Amit Sharma, MD, Whitetree Devices Pvt Ltd, Associate Member TIE, Udaipur</td>
                                        <td>Online</td>
                                        <td>71</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Workshop on Word/ Excel & Power Point Presentation</td>
                                        <td>03-03-2022 to 03-03-2022</td>
                                        <td>Mr. Dheeraj Soni, Udaipur</td>
                                        <td>Offline</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td>Expert Talk on Mathematics</td>
                                        <td>04-03-2022 to 05-03-2022</td>
                                        <td>Mr. Lokesh Kumawat, NIBM, Nathdwara</td>
                                        <td>Offline</td>
                                        <td>81</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>Pre Placement Talk</td>
                                        <td>11-12-21</td>
                                        <td>Mr. Jaideep, Marelli Motherson Automotive Lighting Pvt Ltd, Gujrat</td>
                                        <td>Offline</td>
                                        <td>41</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Civil">Content 3</mat-tab>
    </mat-tab-group> </div>