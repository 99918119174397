<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading main">Fresher Party</h3>
                <p class="para">According to the trends of SSPC, the second year students organize Freshers’ party
                    labeled ‘horizon’ for the I year students to welcome them with open hand and more than that with
                    open heart. The party starts with the welcome speech of
                    the director in which he raises a toast to all the new faces in the college. On this cultural night
                    full with fun and frolic panel of judges adjudge Mr. and Miss Fresher. In this get together party
                    all the students get acquainted with
                    each other.</p>
                <!-- <h5 class="pt-4">Our history</h5>
              <p>Led at felis arcu. Integer lorem lorem, tincidunt eu congue et, mattis ut ante. Nami suscipit, lectus id efficitur ornare, leo libero convalis nulla, vitae dignissim .</p>
                <ul class="about-list">
                    <li><i class="fa fa-check-square-o"></i> University Faculties organise teaching and research into individual subjects.</li>
                    <li><i class="fa fa-check-square-o"></i> The University is rich in history - its famous buildings attract visitors.</li>
                    <li><i class="fa fa-check-square-o"></i> 50 years of people, and achievements that continue to transform.</li>
                    <li><i class="fa fa-check-square-o"></i> The University's core values are as follows:freedom of thought.</li>
                </ul> -->
            </div>
            <!-- <div class="col-lg-6 pt-5 pt-lg-0">
                <img style="max-width: 100%;" src="assets/img/about.jpg" alt="">
            </div> -->
        </div>
    </div>
</section>

<section class="blog-section ng-scope" style="padding-bottom: 10%;">
    <div class="container">
        <div id="gallery-grid">
            <div class="row grid">


                <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10,11,12]" class="col-md-4 col-lg-3 collgwidth">
                    <div class="media media-hoverable justify-content-center galleryheight2">
                        <div class="position-relative w-100 galleryheight2">
                            <img class=" galleryheight2" src="../../../assets/2024updates/Website & Flex Photo/Cultural Events/events/img ({{x}}).JPG" alt="gallery-img">
                        </div>
                    </div>
                </div>


                <div class="col-md-4 col-lg-3 collgwidth">
                    <div class="media media-hoverable justify-content-center galleryheight2">
                        <div class="position-relative w-100 galleryheight2">
                            <img class=" galleryheight2" src="assets/img/fresher/fresher1.jpg" alt="gallery-img">
                        </div>
                    </div>

                </div>

                <div class="col-md-4 col-lg-4 collgwidth charity">

                    <img class=" galleryheight2" src="assets/img/fresher/fresher2.jpg" alt="gallery-img">


                </div>

                <div class="col-md-4 col-lg-4 collgwidth children nature">

                    <img class=" galleryheight2" src="assets/img/fresher/fresher3.jpg" alt="gallery-img">


                </div>

                <!-- <div class="col-md-4 col-lg-3 collgwidth children charity" style="position: absolute; left: 877px; top: 0px;height: 220px !important;">

                    <img class=" galleryheight2" data-src="assets/img/fresher/fresher4.jpg" src="assets/img/fresher/fresher4.jpg" alt="gallery-img">


                </div> -->

                <div class="col-md-4 col-lg-4 collgwidth nature">

                    <img class="galleryheight2" data-src="assets/img/fresher/fresher5.jpg"
                        src="assets/img/fresher/fresher5.jpg" alt="gallery-img">


                </div>

                <div class="col-md-4 col-lg-4 collgwidth children charity">

                    <img class="galleryheight2" data-src="assets/img/fresher/fresher6.jpg"
                        src="assets/img/fresher/fresher6.jpg" alt="gallery-img">

                </div>

                <div class="col-md-4 col-lg-4 collgwidth children charity">

                    <img class="galleryheight2" data-src="assets/img/fresher/fresher7.jpg"
                        src="assets/img/fresher/fresher7.jpg" alt="gallery-img">


                </div>

                <!-- <div class="col-md-4 col-lg-3 collgwidth nature" style="position: absolute; left: 877px; top: 238px;height: 220px !important;">

                    <img class="galleryheight2" data-src="assets/img/fresher/fresher8.jpg" src="assets/img/fresher/fresher8.jpg" alt="gallery-img">


                </div> -->
            </div>
        </div>
    </div>
</section>
<style>
    img {
        max-width: 100% !important;
    }

    .collgwidth {
        max-width: 100% !important;
        margin-top: 2%;
        flex: 0 0 33% !important;
    }
</style>